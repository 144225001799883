import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'


//On importe les composants
import Header from '../components/header'
import Footer from '../components/footer'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'



export default function MentionsLegales() {



    return (
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Mentions légales du cabinet Debrabant Avocat</title>
                <meta name="description" content="Retrouvez les mentions légales et informations juridiques à propos du cabinet Debrabant Avocat." />
                <html lang="fr" />
                <link rel="icon" href={Favicon} />


            </Helmet>

            <Header />

            <div className="BackGrey">
                <div className="BandeauMain">

                    <div className="BandeauPageContainer ItemArticle">

                        <h1>MENTIONS LÉGALES</h1>

                        <div className="ItemArticle">


                            <p>Le présent site internet est édité par Maître Thierry DEBRABANT.</p>

                            <p>Responsable de publication : Maître Thierry DEBRABANT</p>

                            <p>Site internet réalisé par <a href="https://minuccitech.com">Minucci Tech</a>.</p>

                            <p>Hébergeur : Scalingo SAS, 3 place de Haguenau à Strasbourg, France.</p>

                            <h2>OBJET DU SITE INTERNET</h2>
                            <p>Le présent site internet a pour objet la présentation des membres et compétences du cabinet. Il offre un accès privilégié à l'agenda de ses membres pour la prise de rendez-vous en ligne et une veille juridique ou actualité par le biais d'articles.</p>

                            <h2>DROITS D'AUTEUR</h2>
                            <p>Que ce soit la présentation visuelle, le logo, le contenu écrit, le contenu audiovisuel, les photographies, les fonctionnalités du site internet... L'ensemble de ces éléments sont protégés par le droit d'auteur et ne peuvent être reproduits sans l'accord préalable du cabinet.</p>

                            <h2>DONNEES A CARACTERE PERSONNEL</h2>
                            <p>Le présent site internet utilise des cookies dans le but de connaître les chiffres de fréquentation de ses différentes pages. L'objectif est de vous proposer du contenu de meilleur qualité, qui réponde parfaitement à vos attentes. Vos données personnelles sont collectées lors d'une prise de contact avec notamment le formulaire de contact, lorsque vous prenez rendez-vous. Il vous est demandé, uniquement les informations nécessaires pour pouvoir vous recontacter ou répondre à votre problématique.</p>

                            <p>Conformément au RGPD (règlement général sur la protection des données), vous disposez d’un droit d’information, d’accès, de rectification, d’effacement, de limitation, de portabilité et d’opposition sur vos données personnelles. Pour exercer ce droit, contactez-nous en nous envoyant un message via le formulaire ci-dessous.</p>

                        </div>

                    </div>

                </div>

            </div>




            <Footer />


        </HelmetProvider >
    )
}
